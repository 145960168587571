<template>
	<section id="elements" class="w-80 xs:mx-auto">
		<div
			id="el-0"
			class="show pointer-events-none absolute w-64 sm:w-80 ml-4"
			style="top:7rem"
		>
			<p
				id="0"
				class="review pointer-events-none font-poppins text-sm xl:transform xl:translate-x-28"
			>
				‘Joao called at 9.17 because his Renault Megane had a flat battery. We
				arrived 26 minutes later and Joao was back on the road shortly after.’
			</p>

			<div class="absolute left-24">
				<svg
					class="map-marker cursor-pointer"
					xmlns="http://www.w3.org/2000/svg"
					width="23.417"
					height="31.223"
					viewBox="0 0 23.417 31.223"
				>
					<defs></defs>
					<path
						d="M10.505,30.593C1.645,17.748,0,16.429,0,11.708a11.708,11.708,0,0,1,23.417,0c0,4.721-1.645,6.039-10.505,18.884a1.464,1.464,0,0,1-2.407,0Zm1.2-14.006A4.879,4.879,0,1,0,6.83,11.708,4.879,4.879,0,0,0,11.708,16.587Z"
					/>
				</svg>
			</div>
		</div>
		<div
			id="el-1"
			class="pointer-events-none absolute w-64 sm:w-80 ml-8"
			style="top:12rem"
		>
			<p
				id="1"
				class="review pointer-events-none font-poppins text-sm xl:transform xl:-translate-x-40"
			>
				‘Louisa's clutch went on her Hyundai at 13.47. We arrived within 45
				minutes and recovered her vehicle to her preferred repair centre.’
			</p>

			<div class="absolute left-20">
				<svg
					class="map-marker cursor-pointer"
					xmlns="http://www.w3.org/2000/svg"
					width="23.417"
					height="31.223"
					viewBox="0 0 23.417 31.223"
				>
					<defs></defs>
					<path
						d="M10.505,30.593C1.645,17.748,0,16.429,0,11.708a11.708,11.708,0,0,1,23.417,0c0,4.721-1.645,6.039-10.505,18.884a1.464,1.464,0,0,1-2.407,0Zm1.2-14.006A4.879,4.879,0,1,0,6.83,11.708,4.879,4.879,0,0,0,11.708,16.587Z"
					/>
				</svg>
			</div>
		</div>
		<div
			id="el-2"
			class="pointer-events-none absolute w-64 sm:w-80 ml-10 "
			style="top:15rem"
		>
			<p
				id="2"
				class="review pointer-events-none font-poppins text-sm xl:transform xl:translate-x-36"
			>
				‘The fuel pump on Ian's Volkswagen Golf went at 9.35. By 11am we had
				recovered Ians vehicle 30 miles to his chosen garage.’
			</p>

			<div class="absolute left-36">
				<svg
					class="map-marker cursor-pointer"
					xmlns="http://www.w3.org/2000/svg"
					width="23.417"
					height="31.223"
					viewBox="0 0 23.417 31.223"
				>
					<defs></defs>
					<path
						d="M10.505,30.593C1.645,17.748,0,16.429,0,11.708a11.708,11.708,0,0,1,23.417,0c0,4.721-1.645,6.039-10.505,18.884a1.464,1.464,0,0,1-2.407,0Zm1.2-14.006A4.879,4.879,0,1,0,6.83,11.708,4.879,4.879,0,0,0,11.708,16.587Z"
					/>
				</svg>
			</div>
		</div>
		<div
			id="el-3"
			class="pointer-events-none absolute w-64 sm:w-80 ml-10"
			style="top:16rem"
		>
			<p
				id="3"
				class="review pointer-events-none font-poppins text-sm xl:transform xl:-translate-x-32"
			>
				‘The battery on James' 2 year old Lexus went flat. Within 1 hour we had
				recovered the vehicle back to the dealership he purchased from.’
			</p>

			<div class="absolute left-28">
				<svg
					class="map-marker cursor-pointer"
					xmlns="http://www.w3.org/2000/svg"
					width="23.417"
					height="31.223"
					viewBox="0 0 23.417 31.223"
				>
					<defs></defs>
					<path
						d="M10.505,30.593C1.645,17.748,0,16.429,0,11.708a11.708,11.708,0,0,1,23.417,0c0,4.721-1.645,6.039-10.505,18.884a1.464,1.464,0,0,1-2.407,0Zm1.2-14.006A4.879,4.879,0,1,0,6.83,11.708,4.879,4.879,0,0,0,11.708,16.587Z"
					/>
				</svg>
			</div>
		</div>
		<div
			id="el-4"
			class="pointer-events-none absolute w-64 sm:w-80 ml-10"
			style="top:18rem"
		>
			<p
				id="4"
				class="review pointer-events-none font-poppins text-sm xl:transform xl:translate-x-40"
			>
				‘Kerry called us at 15:24 when she noticed she had a slow puncture on
				her Fiat 500. We were at the scene by 16.05 and fitted her spare tyre
				within minutes.’
			</p>

			<div class="absolute left-40">
				<svg
					class="map-marker cursor-pointer"
					xmlns="http://www.w3.org/2000/svg"
					width="23.417"
					height="31.223"
					viewBox="0 0 23.417 31.223"
				>
					<defs></defs>
					<path
						d="M10.505,30.593C1.645,17.748,0,16.429,0,11.708a11.708,11.708,0,0,1,23.417,0c0,4.721-1.645,6.039-10.505,18.884a1.464,1.464,0,0,1-2.407,0Zm1.2-14.006A4.879,4.879,0,1,0,6.83,11.708,4.879,4.879,0,0,0,11.708,16.587Z"
					/>
				</svg>
			</div>
		</div>
		<div
			id="el-5"
			class="pointer-events-none absolute w-64 sm:w-80 ml-10"
			style="top:20rem"
		>
			<p
				id="5"
				class="review pointer-events-none font-poppins text-sm xl:transform xl:-translate-x-28"
			>
				‘Lorraine hit debris on the M6 which caused a puncture. We attended
				within 25 minutes and fitted her spare wheel so she could continue her
				journey.’
			</p>

			<div class="absolute left-32">
				<svg
					class="map-marker cursor-pointer"
					xmlns="http://www.w3.org/2000/svg"
					width="23.417"
					height="31.223"
					viewBox="0 0 23.417 31.223"
				>
					<defs></defs>
					<path
						d="M10.505,30.593C1.645,17.748,0,16.429,0,11.708a11.708,11.708,0,0,1,23.417,0c0,4.721-1.645,6.039-10.505,18.884a1.464,1.464,0,0,1-2.407,0Zm1.2-14.006A4.879,4.879,0,1,0,6.83,11.708,4.879,4.879,0,0,0,11.708,16.587Z"
					/>
				</svg>
			</div>
		</div>
		<div
			id="el-6"
			class="pointer-events-none absolute w-64 sm:w-80 ml-10"
			style="top:23rem"
		>
			<p
				id="6"
				class="review font-poppins text-sm xl:transform xl:-translate-x-40"
			>
				‘Gilian called us at 6.30 am when her Porsche wouldn't start. We arrived
				and jump started her battery within the hour. ’
			</p>

			<div class="absolute left-24">
				<svg
					class="map-marker cursor-pointer"
					xmlns="http://www.w3.org/2000/svg"
					width="23.417"
					height="31.223"
					viewBox="0 0 23.417 31.223"
				>
					<defs></defs>
					<path
						d="M10.505,30.593C1.645,17.748,0,16.429,0,11.708a11.708,11.708,0,0,1,23.417,0c0,4.721-1.645,6.039-10.505,18.884a1.464,1.464,0,0,1-2.407,0Zm1.2-14.006A4.879,4.879,0,1,0,6.83,11.708,4.879,4.879,0,0,0,11.708,16.587Z"
					/>
				</svg>
			</div>
		</div>
		<div
			id="el-7"
			class="pointer-events-none absolute w-64 sm:w-80 ml-10"
			style="top:23rem"
		>
			<p
				id="7"
				class="review font-poppins text-sm xl:transform xl:-translate-x-28"
			>
				‘Steven was involved in a collision on his commute. We collected his car
				from the roadside and stored it until the at-fault drivers insurer
				arranged repairs.’
			</p>

			<div class="absolute left-36">
				<svg
					class="map-marker cursor-pointer"
					xmlns="http://www.w3.org/2000/svg"
					width="23.417"
					height="31.223"
					viewBox="0 0 23.417 31.223"
				>
					<defs></defs>
					<path
						d="M10.505,30.593C1.645,17.748,0,16.429,0,11.708a11.708,11.708,0,0,1,23.417,0c0,4.721-1.645,6.039-10.505,18.884a1.464,1.464,0,0,1-2.407,0Zm1.2-14.006A4.879,4.879,0,1,0,6.83,11.708,4.879,4.879,0,0,0,11.708,16.587Z"
					/>
				</svg>
			</div>
		</div>
		<div
			id="el-8"
			class="pointer-events-none absolute w-64 sm:w-80 ml-10"
			style="top:23rem"
		>
			<p
				id="8"
				class="review font-poppins text-sm xl:transform xl:translate-x-56"
			>
				‘The clutch on Rachel's Audi Q2 went in the centre of London. We
				recovered her vehicle back to her preferred garage in Kent within 2
				hours.’
			</p>

			<div class="absolute left-56">
				<svg
					class="map-marker cursor-pointer"
					xmlns="http://www.w3.org/2000/svg"
					width="23.417"
					height="31.223"
					viewBox="0 0 23.417 31.223"
				>
					<defs></defs>
					<path
						d="M10.505,30.593C1.645,17.748,0,16.429,0,11.708a11.708,11.708,0,0,1,23.417,0c0,4.721-1.645,6.039-10.505,18.884a1.464,1.464,0,0,1-2.407,0Zm1.2-14.006A4.879,4.879,0,1,0,6.83,11.708,4.879,4.879,0,0,0,11.708,16.587Z"
					/>
				</svg>
			</div>
		</div>
		<div
			id="el-9"
			class="pointer-events-none absolute w-64 sm:w-80 ml-10"
			style="top:25.5rem"
		>
			<p
				id="9"
				class="review font-poppins text-sm xl:transform xl:translate-x-48"
			>
				‘Leonard's Jaguar XF wouldn't start on the driveway before work. Our
				service van arrived within 45 minutes and he was on the road within the
				hour.’
			</p>

			<div class="absolute left-48">
				<svg
					class="map-marker cursor-pointer"
					xmlns="http://www.w3.org/2000/svg"
					width="23.417"
					height="31.223"
					viewBox="0 0 23.417 31.223"
				>
					<defs></defs>
					<path
						d="M10.505,30.593C1.645,17.748,0,16.429,0,11.708a11.708,11.708,0,0,1,23.417,0c0,4.721-1.645,6.039-10.505,18.884a1.464,1.464,0,0,1-2.407,0Zm1.2-14.006A4.879,4.879,0,1,0,6.83,11.708,4.879,4.879,0,0,0,11.708,16.587Z"
					/>
				</svg>
			</div>
		</div>
		<div
			id="el-10"
			class="pointer-events-none absolute w-64 sm:w-80 ml-10"
			style="top:27rem"
		>
			<p
				id="10"
				class="review font-poppins text-sm xl:transform xl:-translate-x-40"
			>
				‘Stacy had a blowout on the motorway on the way to university. We
				attended and fit her spare wheel within the hour, allowing her to
				continue her journey.’
			</p>

			<div class="absolute left-20">
				<svg
					class="map-marker cursor-pointer"
					xmlns="http://www.w3.org/2000/svg"
					width="23.417"
					height="31.223"
					viewBox="0 0 23.417 31.223"
				>
					<defs></defs>
					<path
						d="M10.505,30.593C1.645,17.748,0,16.429,0,11.708a11.708,11.708,0,0,1,23.417,0c0,4.721-1.645,6.039-10.505,18.884a1.464,1.464,0,0,1-2.407,0Zm1.2-14.006A4.879,4.879,0,1,0,6.83,11.708,4.879,4.879,0,0,0,11.708,16.587Z"
					/>
				</svg>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'map-cust-reviews',
	computed: {},
	props: {},
	methods: {
		reviewTimer() {
			let c = 0,
				pauseFor = 10000, //ms
				totalSlides = document.getElementById('elements').childElementCount // number of elements to loop through

			const nextSlide = () => {
				document.getElementsByClassName('show')[0].classList.remove('show') // hide currently shown element, [0] takes first in list on muliple classes found
				document.getElementById('el-' + c).classList.add('show')

				if (c === totalSlides - 1) {
					c = 0
					return
				}

				c++
			}

			let intervalID = null // startTimer = setInterval(nextSlide, pauseFor)

			function intervalManager(bool, animate, time) {
				if (bool) {
					intervalID = setInterval(animate, time)
				} else {
					clearInterval(intervalID)
				}
			}
			intervalManager(true, nextSlide, pauseFor)

			const markers = document.getElementsByClassName('map-marker')

			for (let i = 0; i < markers.length; i++) {
				markers[i].addEventListener('click', (e) => {
					intervalManager(false)

					document.getElementsByClassName('show')[0].classList.remove('show')
					document.getElementById('el-' + [i]).classList.add('show')

					intervalManager(true, nextSlide, pauseFor)
				})
			}
		},
	},
	beforeMount() {},
	mounted() {
		this.reviewTimer()
	},
}
</script>

<style scoped>
div {
	position: absolute;
}
p {
	background-color: #404040;
	border-radius: 10px;
	color: #cecece;
	padding: 6%;
	font-size: 12px;
}
.review {
	opacity: 0;
	transition: 0.2s;
}
.show .review {
	opacity: 1;
	transition: 1s;
	z-index: 999;
}
.map-marker {
	fill: #27dafe;
	opacity: 0.4;
	transform-origin: 50% bottom;
	transform: scale(0.5);
	width: 46.8px;
	height: 62.4px;
	pointer-events: auto;
}
.show .map-marker {
	opacity: 1;
	transition: 1s;
	transform-origin: 50% bottom;
	transform: scale(1);
}
</style>
