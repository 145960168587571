<template>
	<div class="w-full" v-if="deadline">
		<div class="mt-2"
			:class="{
                'join-flow-timer mt-1 lg:mt-2' : version == 'joinflow',
        	}"
		>
			<div
                                :class="{
                    'join-flow-timer mt-1 lg:mt-2' : version == 'joinflow',
                    'cyan': targetColour == '27YGU4',
                    'yellow': targetColour == '42ASP1' || targetColour == 4,
                    'orange': targetColour == '51TLO7' || targetColour == 5,
                    'green': targetColour == '18BDA8' || targetColour == 6,
                }"
            >
				<flip-countdown :deadline="deadline"></flip-countdown>
			</div>
		</div>
	</div>
</template>

<script>
import FlipCountdown from 'vue2-flip-countdown'

export default {
	name: 'DiscountTimer',
	components: {
		FlipCountdown,
	},
	computed: {
		deadlineFormatted() {
			return this.$moment(this.deadline).format('YYYY-MM-DD HH:mm:ss')
		},
	},
	props: {
		deadline: {
			type: String,
			default: '',
		},
		version: {
			type: String,
			default: '',
		},
		targetColour: {
			type: [String, Number],
			default: '',
		},
	},
	methods: {},
	mounted() {
		window.addEventListener('load', () => {
			const discountBanner = document.querySelector('#discount-banner')
			const intersector = document.querySelector('#show-nav')
			if (discountBanner && intersector) {
				let observer = new IntersectionObserver((entries, observer) => {
					// when intersector is visable hide discount banner
					if (entries[0].isIntersecting) {
						discountBanner.classList.add('transform')
					}

					if (!entries[0].isIntersecting) {
						discountBanner.classList.remove('transform')
					}
				})
				observer.observe(intersector)
			}
		})
	},
}
</script>

<style>
.flip-card__top-4digits,
.flip-card__bottom-4digits,
.flip-card__back-bottom-4digits,
.flip-card__back-4digits::before,
.flip-card__back-4digits::after,
.flip-card__top,
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__back::before,
.flip-card__back::after {
	color: #fff !important;
	border-top: 1px solid #dc2626 !important;
}

.flip-card__top,
.flip-card__back::before,
.flip-card__back::after {
	background: #dc2626 !important;
}

.flip-card__back-bottom {
	background: #ef4444 !important;
}

.flip-card__bottom {
	background: #ef4444 !important;
}

#timer-hero .flip-clock__slot,
#timer-mot-hero .flip-clock__slot {
	color: white;
}

/* Green Version #5ecb4c #70ed5b  -- #52ae43 */
.green .flip-card__top-4digits,
.green .flip-card__bottom-4digits,
.green .flip-card__back-bottom-4digits,
.green .flip-card__back-4digits::before,
.green .flip-card__back-4digits::after,
.green .flip-card__top,
.green .flip-card__bottom,
.green .flip-card__back-bottom,
.green .flip-card__back::before,
.green .flip-card__back::after {
	color: #fff !important;
	border-top: 1px solid #52ae43 !important;
}

.green .flip-card__top,
.green .flip-card__back::before,
.green .flip-card__back::after {
	background: #52ae43 !important;
}

.green .flip-card__back-bottom {
	background: #5ecb4c !important;
}

.green .flip-card__bottom {
	background: #5ecb4c !important;
}

#timer-hero .green .flip-clock__slot,
#timer-mot-hero .green .flip-clock__slot {
	color: #404040;
}

nav .green .flip-clock__slot,
nav .green .flip-clock__slot {
	color: #ffffff !important;
}

/* FIN: green Version */

/* orange Version #5ecb4c #70ed5b  -- #52ae43 */
.orange .flip-card__top-4digits,
.orange .flip-card__bottom-4digits,
.orange .flip-card__back-bottom-4digits,
.orange .flip-card__back-4digits::before,
.orange .flip-card__back-4digits::after,
.orange .flip-card__top,
.orange .flip-card__bottom,
.orange .flip-card__back-bottom,
.orange .flip-card__back::before,
.orange .flip-card__back::after {
	color: #fff !important;
	border-top: 1px solid #FE5B00 !important;
}

.orange .flip-card__top,
.orange .flip-card__back::before,
.orange .flip-card__back::after {
	background: #FE5B00 !important;
}

.orange .flip-card__back-bottom {
	background: #f97d37 !important;
}

.orange .flip-card__bottom {
	background: #f97d37 !important;
}

#timer-hero .orange .flip-clock__slot,
#timer-mot-hero .orange .flip-clock__slot {
	color: #404040;
}

nav .orange .flip-clock__slot,
nav .orange .flip-clock__slot {
	color: #ffffff !important;
}

/* FIN: orange Version */


/* yellow Version #5ecb4c #70ed5b  -- #52ae43 */
.yellow .flip-card__top-4digits,
.yellow .flip-card__bottom-4digits,
.yellow .flip-card__back-bottom-4digits,
.yellow .flip-card__back-4digits::before,
.yellow .flip-card__back-4digits::after,
.yellow .flip-card__top,
.yellow .flip-card__bottom,
.yellow .flip-card__back-bottom,
.yellow .flip-card__back::before,
.yellow .flip-card__back::after {
	color: #fff !important;
	border-top: 1px solid #d6a103 !important;
}

.yellow .flip-card__top,
.yellow .flip-card__back::before,
.yellow .flip-card__back::after {
	background: #d6a103 !important;
}

.yellow .flip-card__back-bottom {
	background: #FFBF00 !important;
}

.yellow .flip-card__bottom {
	background: #FFBF00 !important;
}

#timer-hero .yellow .flip-clock__slot,
#timer-mot-hero .yellow .flip-clock__slot {
	color: #404040;
}

nav .yellow .flip-clock__slot,
nav .yellow .flip-clock__slot {
	color: #ffffff !important;
}

/* FIN: yellow Version */

/* Cyan Version #00DDFF */
.cyan .flip-card__top-4digits,
.cyan .flip-card__bottom-4digits,
.cyan .flip-card__back-bottom-4digits,
.cyan .flip-card__back-4digits::before,
.cyan .flip-card__back-4digits::after,
.cyan .flip-card__top,
.cyan .flip-card__bottom,
.cyan .flip-card__back-bottom,
.cyan .flip-card__back::before,
.cyan .flip-card__back::after {
    color: #fff !important;
    border-top: 1px solid #00BBCC !important;
}
.cyan .flip-card__top,
.cyan .flip-card__back::before,
.cyan .flip-card__back::after {
    background: #00BBCC !important;
}

.cyan .flip-card__back-bottom {
    background: #00DDFF !important;
}

.cyan .flip-card__bottom {
    background: #00DDFF !important;
}

#timer-hero .cyan .flip-clock__slot,
#timer-mot-hero .cyan .flip-clock__slot {
    color: #404040;
}

nav .cyan .flip-clock__slot,
nav .cyan .flip-clock__slot {
    color: #ffffff !important;
}
















.flip-card {
	font-size: 1.8rem !important;
}
.flip-clock__slot {
	font-size: 0.8rem !important;
}

.join-flow-timer .flip-card {
	font-size: 1.0rem !important;
}

.join-flow-timer .flip-clock__slot {
	font-size: 0.5rem !important;
}

@media screen and (min-width: 390px) {
	.join-flow-timer .flip-card {
		font-size: 1.1rem !important;
	}

	.join-flow-timer .flip-clock__slot {
		font-size: 0.6rem !important;
	}
}

@media screen and (min-width: 640px) {
	.flip-card {
		font-size: 1.1rem !important;
	}

	.flip-clock__slot {
		font-size: 0.6rem !important;
	}
}

@media screen and (min-width: 768px) {
	.flip-card {
		font-size: 1.8rem !important;
	}

	.flip-clock__slot {
		font-size: 0.8rem !important;
	}

	.join-flow-timer .flip-card {
		font-size: 1.1rem !important;
	}

	.join-flow-timer .flip-clock__slot {
		font-size: 0.6rem !important;
	}
}

@media screen and (min-width: 1024px) and (max-width: 1055px) {
	.flip-card {
		font-size: 1.5rem !important;
	}

	.flip-clock__piece {
		margin: 0 2px !important;
	}
}

@media only screen and (min-width: 1024px) {
	#timer-hero .flip-card {
		font-size: 2.2rem !important;
	}
	#timer-hero .flip-clock__slot,
	#timer-mot-hero .flip-clock__slot {
		font-size: 1rem !important;
	}
}

@media screen and (min-width: 1024px) {
	.join-flow-timer .flip-card {
		font-size: 1.4rem !important;
	}

	.join-flow-timer .flip-clock__slot {
		font-size: 0.6rem !important;
	}
}
</style>
