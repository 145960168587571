import DiscountTimer from './components/DiscountTimer'

let idExists = document.querySelector('#discount-banner')
if (idExists) {
	const timerApp = new window.Vue({
		el: '#discount-banner',
		components: {
			DiscountTimer,
		},
		methods: {},
		mounted() {},
	})
}
idExists = document.querySelector('#timer-hero')
if (idExists) {
	const timerHeroApp = new window.Vue({
		el: '#timer-hero',
		components: {
			DiscountTimer,
		},
		methods: {},
		mounted() {},
	})
}
import FeatureCarousel from './components/FeatureCarousel'
import FeatureCarouselTargeted from './components/FeatureCarouselTargeted'
const carouselApp = new window.Vue({
    el: '#carousel',
    components: {
        FeatureCarousel,
        FeatureCarouselTargeted
    },
    methods: {},
    mounted() {
    },
})

import MapCustReviews from './components/MapCustReviews'

const mapApp = new window.Vue({
    el: '#map',
    components: {
        MapCustReviews,
    },
    methods: {},
    mounted() {
    },
})
