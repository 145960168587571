var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.deadline)?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"mt-2",class:{
                'join-flow-timer mt-1 lg:mt-2' : _vm.version == 'joinflow',
        	}},[_c('div',{class:{
                    'join-flow-timer mt-1 lg:mt-2' : _vm.version == 'joinflow',
                    'cyan': _vm.targetColour == '27YGU4',
                    'yellow': _vm.targetColour == '42ASP1' || _vm.targetColour == 4,
                    'orange': _vm.targetColour == '51TLO7' || _vm.targetColour == 5,
                    'green': _vm.targetColour == '18BDA8' || _vm.targetColour == 6,
                }},[_c('flip-countdown',{attrs:{"deadline":_vm.deadline}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }