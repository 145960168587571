<template>
	<carousel
		:scrollPerPage="true"
		:perPageCustom="[
			[0, 1],
			[640, 2],
			[1200, 3],
		]"
		:autoplay="true"
		:autoplayTimeout="7000"
		:navigateTo="1"
		:loop="true"
		class="mt-10"
	>
		<slide v-for="feature in features" :key="feature.title">
			<div
				class="h-full flow-root bg-midgray rounded-md mx-2 px-2 pb-8 md:mx-4"
			>
				<div>
					<span
						v-html="feature.icon"
						class="inline-flex items-center justify-center mt-10"
					></span>
				</div>
				<h3
				    class="font-poppins mt-10 text-2xl font-bold text-white px-4 lg:text-4xl lg:font-base"
				>
					{{ feature.title }}
				</h3>
				<p
					class="my-8 px-4 text-sm font-thin text-gray-300 lg:text-lg lg:font-normal"
				>
					{{ feature.blurb }}
				</p>
			</div>
		</slide>
	</carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
	name: 'FeatureCarousel',
	components: { Carousel, Slide },
	props: {
		percent: {
			default: 78,
		},
	},
	data() {
		return {
			features: [
				{
					icon: `<svg xmlns="http://www.w3.org/2000/svg" width="32.266" height="43.021" viewBox="0 0 32.266 43.021"><defs></defs><path class="clipboard-a" d="M22.628,18.973a.845.845,0,0,0-1.185-.008L14.049,26.3l-3.2-3.235a.845.845,0,0,0-1.185-.008L7.68,25.023a.845.845,0,0,0-.008,1.185l5.756,5.806a.845.845,0,0,0,1.185.008l9.966-9.881a.845.845,0,0,0,.008-1.185l-1.958-1.983Zm5.6-13.6H21.511a5.378,5.378,0,0,0-10.755,0H4.033A4.034,4.034,0,0,0,0,9.411V38.988a4.034,4.034,0,0,0,4.033,4.033h24.2a4.034,4.034,0,0,0,4.033-4.033V9.411A4.034,4.034,0,0,0,28.233,5.378Zm-12.1-1.344a1.344,1.344,0,1,1-1.344,1.344A1.348,1.348,0,0,1,16.133,4.033Zm12.1,34.283a.674.674,0,0,1-.672.672H4.705a.674.674,0,0,1-.672-.672V10.083a.674.674,0,0,1,.672-.672H8.067V12.1a1.348,1.348,0,0,0,1.344,1.344H22.855A1.348,1.348,0,0,0,24.2,12.1V9.411h3.361a.674.674,0,0,1,.672.672Z"/></svg>`,
					title: 'No Hidden Fees',
					blurb:
						'Unlike other competitors we don’t charge cancellation fees or for changing your vehicle.',
				},
				{
					icon: `<svg xmlns="http://www.w3.org/2000/svg" width="27.757" height="38.858" viewBox="0 0 27.757 38.858"><defs></defs><path class="poundSign-a" d="M26.716,59.756H22.77A1.041,1.041,0,0,0,21.729,60.8v4.41H11.1v-11h7.286a1.041,1.041,0,0,0,1.041-1.041V49.694a1.041,1.041,0,0,0-1.041-1.041H11.1V43.141c0-2.8,2.13-4.951,5.36-4.951a10.02,10.02,0,0,1,5,1.635,1.04,1.04,0,0,0,1.361-.233l2.471-3.08a1.04,1.04,0,0,0-.184-1.482A14.907,14.907,0,0,0,16.3,32C9.2,32,4.163,36.575,4.163,42.925v5.728H1.735A1.041,1.041,0,0,0,.694,49.694v3.469A1.041,1.041,0,0,0,1.735,54.2H4.163v11.1H1.041A1.041,1.041,0,0,0,0,66.348v3.469a1.041,1.041,0,0,0,1.041,1.041H26.716a1.041,1.041,0,0,0,1.041-1.041V60.8A1.041,1.041,0,0,0,26.716,59.756Z" transform="translate(0 -32)"/></svg>`,
					title: 'Why Pay More?',
					blurb: `Why pay more? We don't waste money on fancy TV ads so we can pass the savings on to you.`,
				},
				{
					icon: `<svg width="39" height="35" viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">
						   <path class="car" d="M5.17688 30.281V32.3858C5.17688 33.0018 4.95443 33.5237 4.50953 33.9515C4.06462 34.3793 3.53416 34.5932 2.91814 34.5932H2.55879C1.94277 34.5932 1.41231 34.3707 0.967405 33.9258C0.522501 33.4809 0.300049 32.9505 0.300049 32.3344V15.5479L4.66353 2.3034C4.9031 1.68738 5.27955 1.19114 5.79291 0.814686C6.30626 0.438229 6.90517 0.25 7.58963 0.25H31.4091C32.0936 0.25 32.6925 0.438229 33.2059 0.814686C33.7192 1.19114 34.0957 1.68738 34.3352 2.3034L38.7501 15.5479V32.3344C38.7501 32.9505 38.519 33.4809 38.057 33.9258C37.595 34.3707 37.056 34.5932 36.44 34.5932H36.0293C35.379 34.5932 34.8486 34.3793 34.4379 33.9515C34.0272 33.5237 33.8219 33.0018 33.8219 32.3858V30.281H5.17688ZM5.58757 12.6218H33.4112L30.6904 4.35681H8.30833L5.58757 12.6218ZM9.69437 23.7615C10.4473 23.7615 11.0975 23.4962 11.6451 22.9658C12.1927 22.4353 12.4665 21.7765 12.4665 20.9894C12.4665 20.2022 12.1927 19.5263 11.6451 18.9616C11.0975 18.397 10.4473 18.1146 9.69437 18.1146C8.87301 18.1146 8.18854 18.397 7.64097 18.9616C7.0934 19.5263 6.81961 20.2022 6.81961 20.9894C6.81961 21.7765 7.0934 22.4353 7.64097 22.9658C8.18854 23.4962 8.87301 23.7615 9.69437 23.7615ZM29.4071 23.7615C30.1942 23.7615 30.8701 23.4962 31.4348 22.9658C31.9995 22.4353 32.2818 21.7765 32.2818 20.9894C32.2818 20.2022 31.9995 19.5263 31.4348 18.9616C30.8701 18.397 30.1942 18.1146 29.4071 18.1146C28.6199 18.1146 27.9611 18.397 27.4307 18.9616C26.9002 19.5263 26.635 20.2022 26.635 20.9894C26.635 21.7765 26.9002 22.4353 27.4307 22.9658C27.9611 23.4962 28.6199 23.7615 29.4071 23.7615ZM4.35552 26.2256H34.6432V15.7019H4.35552V26.2256Z" fill="#00DDFF"/>
						   </svg>`,
					title: 'We Cover The Car',
					blurb:
						'We cover the car, not the person. Meaning your car is covered no matter who is at the wheel.',
				},
				{
					icon: `<svg xmlns="http://www.w3.org/2000/svg" width="40.333" height="43.021" viewBox="0 0 40.333 43.021"><defs></defs><path class="thumbs" d="M39.179,24.09A7.946,7.946,0,0,0,40.333,19.9a7.312,7.312,0,0,0-7.211-7.191H30.056A10.729,10.729,0,0,0,30.8,8.8c0-6.117-3.167-8.8-8.005-8.8-5.177,0-4.881,7.977-6.03,9.125-1.911,1.911-4.169,5.583-5.778,7.008h-8.3A2.689,2.689,0,0,0,0,18.822V38.988a2.689,2.689,0,0,0,2.689,2.689H8.067a2.69,2.69,0,0,0,2.6-2.012c3.74.084,6.307,3.356,14.94,3.356h1.867c6.48,0,9.41-3.313,9.49-8.01a8.1,8.1,0,0,0,1.457-5.629A7.8,7.8,0,0,0,39.179,24.09ZM33.99,28.613a3.731,3.731,0,0,1-1.171,4.837c.647,4.1-1.48,5.537-4.463,5.537H25.178c-6.02,0-9.918-3.178-14.422-3.178V20.166h.918c2.383,0,5.712-5.957,7.944-8.189C22,9.594,21.206,5.622,22.795,4.033c3.972,0,3.972,2.771,3.972,4.767,0,3.291-2.383,4.766-2.383,7.944h8.738A3.282,3.282,0,0,1,36.3,19.922c.008,1.588-1.077,3.177-1.871,3.177A4.037,4.037,0,0,1,33.99,28.613ZM7.394,36.3a2.017,2.017,0,1,1-2.017-2.017A2.017,2.017,0,0,1,7.394,36.3Z"/></svg>`,
					title: "Trustpilot Rated 'Excellent'",
					blurb:
						'Why settle for good when you can have Excellent. Don\'t just take our word for it, see what our customers say below.',
				},
				{
					icon: `<svg xmlns="http://www.w3.org/2000/svg" width="43.015" height="43.021" viewBox="0 0 43.015 43.021"><defs></defs><path class="tools" d="M18.82,8.074v4.1l2.5,2.5a8.865,8.865,0,0,1,8.7-10.628h.1L25.289,8.847l1.269,7.612,7.612,1.269,4.814-4.814a8.843,8.843,0,0,1-5.293,8.209c.151.134.328.244.471.386l2.579,2.579a12.541,12.541,0,0,0,2.47-1.924,13.059,13.059,0,0,0,3.42-12.317,2.918,2.918,0,0,0-4.907-1.361l-4.932,4.923-2.722-.454-.454-2.722L34.54,5.31A2.932,2.932,0,0,0,33.17.395,12.845,12.845,0,0,0,18.728,6.654l.092.067ZM8.906,38.144a2.934,2.934,0,0,1-4.041,0,2.85,2.85,0,0,1-.84-2.016,2.8,2.8,0,0,1,.84-2.016L16.157,22.819l-2.848-2.848L2.016,31.255A6.892,6.892,0,0,0,11.763,41l8.477-8.477a8.851,8.851,0,0,1-1.319-4.377Zm33.2-4.9-9.839-9.839a6.238,6.238,0,0,0-7.175-1.168l-8.956-8.956V8.066L5.377,0,0,5.377,8.066,16.131h5.218l8.956,8.956a6.252,6.252,0,0,0,1.168,7.175L33.246,42.1a3.124,3.124,0,0,0,4.428,0L42.1,37.674a3.139,3.139,0,0,0,0-4.428Z"/></svg>`,
					title: 'We Fix 4 Out of 5',
					blurb:
						'With over 80% of vehicles repaired at the roadside, our exceptional repair rate means you will be back on the road as quickly as possible.',
				},
				{
					icon: `<svg xmlns="http://www.w3.org/2000/svg" width="34.955" height="43.021" viewBox="0 0 34.955 43.021"><defs></defs><path class="stopwatch" d="M47.754,15.461l1.9-1.9a1.009,1.009,0,0,0,0-1.428L48.224,10.7a1.009,1.009,0,0,0-1.428,0l-1.739,1.739a17.36,17.36,0,0,0-9.562-4.269V4.033h2.353a1.011,1.011,0,0,0,1.008-1.008V1.008A1.011,1.011,0,0,0,37.847,0H29.108A1.011,1.011,0,0,0,28.1,1.008V3.025a1.011,1.011,0,0,0,1.008,1.008h2.353V8.184a17.475,17.475,0,1,0,16.293,7.277ZM33.477,38.988A13.444,13.444,0,1,1,46.922,25.544,13.441,13.441,0,0,1,33.477,38.988Zm1.008-9.411H32.469a1.011,1.011,0,0,1-1.008-1.008V17.141a1.011,1.011,0,0,1,1.008-1.008h2.017a1.011,1.011,0,0,1,1.008,1.008V28.569A1.011,1.011,0,0,1,34.486,29.577Z" transform="translate(-16)"/></svg>`,
					title: '43 Minute Arrival Time',
					blurb:
						'On average we are with you in just 43 minutes. You won’t be waiting long once help is on the way.',
				},
			],
		}
	},
	computed: {},
	methods: {
		removeTooltip() {
			const allElements = document.querySelectorAll('.VueCarousel-dot')
			for (let i = 0; i < allElements.length; i++) {
				allElements[i].title = ''
			}
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.removeTooltip()
		})

        if (window.isBusiness) {
            this.features.splice(4, 2)
        }
	},
}
</script>

<style>
.VueCarousel-pagination .VueCarousel-dot {
	background-color: #404040 !important;
}
.VueCarousel-pagination .VueCarousel-dot:focus {
	outline: none !important;
}
.VueCarousel-pagination .VueCarousel-dot--active {
	background-color: #00ddff !important;
}
.clipboard-a {
	fill: #28ddfe;
}
.poundSign-a {
	fill: #28ddfe;
}
.stopwatch {
	fill: #27dafe;
}
.thumbs {
	fill: #28ddfe;
}
.tools {
	fill: #27dafe;
}

.user-a {
	fill: #28ddfe;
}
</style>
