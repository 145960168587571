<template>
	<carousel
		:scrollPerPage="true"
		:perPageCustom="[
			[0, 1],
			[640, 2],
			[1200, 3],
		]"
		:autoplay="true"
		:autoplayTimeout="7000"
		:navigateTo="1"
		:loop="true"
		class="mt-10"
	>
		<slide v-for="feature in features" :key="feature.title">
			<div
				class="h-full flow-root bg-midgray rounded-md mx-2 px-2 pb-8 md:mx-4"
			>
				<div>
					<span
						v-html="feature.icon"
						class="inline-flex items-center justify-center mt-10"
					></span>
				</div>
				<h3
				    class="font-poppins mt-10 text-2xl font-bold text-white px-4 lg:text-4xl lg:font-base"
				>
					{{ feature.title }}
				</h3>
				<p
					class="my-8 px-4 text-sm font-thin text-gray-300 lg:text-lg lg:font-normal"
				>
					{{ feature.blurb }}
				</p>
			</div>
		</slide>
	</carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
	name: 'FeatureCarouselTargeted',
	components: { Carousel, Slide },
	props: {
		percent: {
			default: 78,
		},
	},
	data() {
		return {
			features: [
				{
					icon: `<svg xmlns="http://www.w3.org/2000/svg" width="43.015" height="43.021" viewBox="0 0 43.015 43.021"><defs></defs><path class="tools" d="M18.82,8.074v4.1l2.5,2.5a8.865,8.865,0,0,1,8.7-10.628h.1L25.289,8.847l1.269,7.612,7.612,1.269,4.814-4.814a8.843,8.843,0,0,1-5.293,8.209c.151.134.328.244.471.386l2.579,2.579a12.541,12.541,0,0,0,2.47-1.924,13.059,13.059,0,0,0,3.42-12.317,2.918,2.918,0,0,0-4.907-1.361l-4.932,4.923-2.722-.454-.454-2.722L34.54,5.31A2.932,2.932,0,0,0,33.17.395,12.845,12.845,0,0,0,18.728,6.654l.092.067ZM8.906,38.144a2.934,2.934,0,0,1-4.041,0,2.85,2.85,0,0,1-.84-2.016,2.8,2.8,0,0,1,.84-2.016L16.157,22.819l-2.848-2.848L2.016,31.255A6.892,6.892,0,0,0,11.763,41l8.477-8.477a8.851,8.851,0,0,1-1.319-4.377Zm33.2-4.9-9.839-9.839a6.238,6.238,0,0,0-7.175-1.168l-8.956-8.956V8.066L5.377,0,0,5.377,8.066,16.131h5.218l8.956,8.956a6.252,6.252,0,0,0,1.168,7.175L33.246,42.1a3.124,3.124,0,0,0,4.428,0L42.1,37.674a3.139,3.139,0,0,0,0-4.428Z"/></svg>`,
					title: 'We Fix 4 Out of 5',
					blurb:
						'With over 80% of vehicles repaired at the roadside, our exceptional repair rate means you will be back on the road as quickly as possible.',
				},
				{
					icon: `<svg xmlns="http://www.w3.org/2000/svg" width="34.955" height="43.021" viewBox="0 0 34.955 43.021"><defs></defs><path class="stopwatch" d="M47.754,15.461l1.9-1.9a1.009,1.009,0,0,0,0-1.428L48.224,10.7a1.009,1.009,0,0,0-1.428,0l-1.739,1.739a17.36,17.36,0,0,0-9.562-4.269V4.033h2.353a1.011,1.011,0,0,0,1.008-1.008V1.008A1.011,1.011,0,0,0,37.847,0H29.108A1.011,1.011,0,0,0,28.1,1.008V3.025a1.011,1.011,0,0,0,1.008,1.008h2.353V8.184a17.475,17.475,0,1,0,16.293,7.277ZM33.477,38.988A13.444,13.444,0,1,1,46.922,25.544,13.441,13.441,0,0,1,33.477,38.988Zm1.008-9.411H32.469a1.011,1.011,0,0,1-1.008-1.008V17.141a1.011,1.011,0,0,1,1.008-1.008h2.017a1.011,1.011,0,0,1,1.008,1.008V28.569A1.011,1.011,0,0,1,34.486,29.577Z" transform="translate(-16)"/></svg>`,
					title: '43 Minute Arrival Time',
					blurb:
						'On average we are with you in just 43 minutes. You won’t be waiting long once help is on the way.',
				},
				{
					icon: `<svg xmlns="http://www.w3.org/2000/svg" width="27.757" height="38.858" viewBox="0 0 27.757 38.858"><defs></defs><path class="poundSign-a" d="M26.716,59.756H22.77A1.041,1.041,0,0,0,21.729,60.8v4.41H11.1v-11h7.286a1.041,1.041,0,0,0,1.041-1.041V49.694a1.041,1.041,0,0,0-1.041-1.041H11.1V43.141c0-2.8,2.13-4.951,5.36-4.951a10.02,10.02,0,0,1,5,1.635,1.04,1.04,0,0,0,1.361-.233l2.471-3.08a1.04,1.04,0,0,0-.184-1.482A14.907,14.907,0,0,0,16.3,32C9.2,32,4.163,36.575,4.163,42.925v5.728H1.735A1.041,1.041,0,0,0,.694,49.694v3.469A1.041,1.041,0,0,0,1.735,54.2H4.163v11.1H1.041A1.041,1.041,0,0,0,0,66.348v3.469a1.041,1.041,0,0,0,1.041,1.041H26.716a1.041,1.041,0,0,0,1.041-1.041V60.8A1.041,1.041,0,0,0,26.716,59.756Z" transform="translate(0 -32)"/></svg>`,
					title: 'Why Pay More?',
					blurb: `Why pay more? We don't waste money on fancy TV ads so we can pass the savings on to you.`,
				},
			],
		}
	},
	computed: {},
	methods: {
		removeTooltip() {
			const allElements = document.querySelectorAll('.VueCarousel-dot')
			for (let i = 0; i < allElements.length; i++) {
				allElements[i].title = ''
			}
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.removeTooltip()
		})

        if (window.isBusiness) {
            this.features.splice(4, 2)
        }
	},
}
</script>

<style>
.VueCarousel-pagination .VueCarousel-dot {
	background-color: #404040 !important;
}
.VueCarousel-pagination .VueCarousel-dot:focus {
	outline: none !important;
}
.VueCarousel-pagination .VueCarousel-dot--active {
	background-color: #00ddff !important;
}
.clipboard-a {
	fill: #28ddfe;
}
.poundSign-a {
	fill: #28ddfe;
}
.stopwatch {
	fill: #27dafe;
}
.thumbs {
	fill: #28ddfe;
}
.tools {
	fill: #27dafe;
}

.user-a {
	fill: #28ddfe;
}
</style>
